import React from 'react';
import { kebabCase } from 'lodash';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ImageType } from '../components/Content';
import PreviewCompatibleImage from './PreviewCompatibleImage';

class BlogRollTemplate extends React.Component<BlogRollType> {
  render() {
    const { edges: posts } = this.props.data.allMarkdownRemark;

    return (
      <>
        {posts &&
          posts.map(({ node: post }) => (
            <>
              <Row key={post.id}>
                <Col sm={4}>
                  {post.frontmatter.featuredimage ? (
                    <Link to={post.fields.slug}>
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `Bild für Post ${post.frontmatter.title}`,
                        }}
                      />
                    </Link>
                  ) : null}
                </Col>
                <Col sm={8}>
                  <h2>
                    <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                  </h2>
                  <p>
                    <i className="fas fa-calendar-day"></i> {post.frontmatter.date}<br />
                    {post.frontmatter.tags && post.frontmatter.tags.length ? (
                      <>
                        {post.frontmatter.tags.map((tag, idx) => (
                          <span key={`tags-${idx}`} className="tag-element">
                            <Link to={`/tags/${kebabCase(tag)}/`}><i className="fas fa-tag"></i>&nbsp;{tag}</Link>
                          </span>
                        ))}
                      </>
                    ) : null}
                  </p>
                  <p>{post.excerpt}</p>
                  <p>
                    <Link to={post.fields.slug} className="btn btn-primary">
                      Weiterlesen <i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          ))}
      </>
    );
  };
};

export type BlogRollTypeRender = {
  site?: {
    siteMetadata?: {
      title?: string
    }
  },
  allMarkdownRemark: {
    totalCount?: number,
    edges: Array<{
      node: {
        excerpt: string,
        id: string,
        fields: {
          slug: string,
        },
        frontmatter: {
          title: string,
          date: string,
          tags: Array<string>,
          featuredimage: ImageType,
        },
      },
    }>,
  },
};
export type BlogRollType = {
  data: BlogRollTypeRender,
  count?: number,
};

export default BlogRollTemplate;
