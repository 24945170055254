import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import Layout from '../components/Layout';
import BlogRollTemplate, { BlogRollTypeRender } from '../components/BlogRollTemplate';

class TagRoute extends React.Component<TagRouteType> {
  render() {
    const { data } = this.props;
    const tag = this.props.pageContext.tag;
    const title = data.site?.siteMetadata?.title;
    const totalCount = data.allMarkdownRemark.totalCount;

    return (
      <Layout>
        <Container>
          <Helmet title={`${tag} | ${title}`} />
          <h1><i className="fas fa-tag"></i>&nbsp;Tag "{tag}"</h1>
          <p>{totalCount} {totalCount === 1 ? 'Blog-Beitrag' : 'Blog-Beiträge'} mit dem Tag "{tag}"</p>
          <BlogRollTemplate data={data} />
          <p>
            <Link to="/tags" className="btn btn-primary btn-blog-overview">Übersicht aller Tags</Link>
          </p>
          <p>
            <Link to="/blog" className="btn btn-primary">Übersicht aller Blog-Beiträge</Link>
          </p>
        </Container>
      </Layout>
    );
  };
};

type TagRouteType = {
  pageContext: {
    tag: string,
  },
  data: BlogRollTypeRender,
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
              slug
          }
          frontmatter {
            title
            date(formatString: "DD. MMMM YYYY", locale: "de")
            tags
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
